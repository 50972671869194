<template>

<nav class="flix-navbar flix-navbar-expand-lg flix-navbar-light flix-navbar-default">
  <div class="flix-container flix-html-html flix-text-center">

    <div class="flix-navbar-header flix-html-html">
      <button type="button" @click="toggleMenu = !toggleMenu" :class="{'flix-collapsed': toggleMenu}" class="flix-navbar-toggle " data-toggle="collapse" data-target="#bs-example-navbar-collapse-1" aria-expanded="false">
        <span class="sr-only"><flixIcon :id='"menu-hamburger"' /> Navigation</span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
      </button>
    </div>
    <div :class="{'flix-collapse': toggleMenu}" class="flix-navbar-collapse" id="bs-example-navbar-collapse-1">
      <ul class="flix-nav flix-navbar-nav">
        <li class="flix-html-li"><router-link @click.native="closeMenu" class="flix-html-a" :to="getLink('index')"><flixIcon :id="'important-day'" /> {{ $tc("message.appointmentOverview", 1) }}</router-link></li>
        <li class="flix-html-li"><router-link @click.native="closeMenu" class="flix-html-a" :to="getLink('edit-booking')"><flixIcon :id="'git-delete'" /> {{ $tc("message.edit", 1, {name: $tc('message.appointments', 1) }) }} / <span class="flix-text-danger">{{ $tc('message.appointments', 1) }}-{{ $tc('message.cancellation', 1) }}</span></router-link></li>
        <!-- <li class="flix-html-li" v-if="checkWebinar() && data.validated && data.status === 'commitment'"><router-link @click.native="closeMenu" class="flix-html-a" :to="getLink('booking-webinar')"><flixIcon :id="'webcam'" /> {{ $tc("message.webinar", 1) }}</router-link></li>
        <li class="flix-html-li" v-if="data.validated"><router-link @click.native="closeMenu" class="flix-html-a" :to="getLink('booking-data')"><flixIcon :id="'pen'" /> {{ $tc("message.personalData", 1) }}</router-link></li>
        <li class="flix-html-li" v-if="data.validated && data.status === 'commitment'"><router-link @click.native="closeMenu" class="flix-html-a" :to="getLink('check-in')"><flixIcon :id="'stopwatch'" /> {{ $tc("message.checkIn") }}</router-link></li>
        <li class="flix-html-li"><router-link @click.native="closeMenu" class="flix-html-a" :to="getLink('booking-chat')"><flixIcon :id="'conversation'" /> {{ $t('message.userChat') }}</router-link></li>
        <li class="flix-html-li"><router-link @click.native="closeMenu" class="flix-html-a" :to="getLink('imprint')"><flixIcon :id="'paragraph-alt'" /> {{ $t('message.imprint') }} &amp; {{ $t('message.privacy') }}</router-link></li>
        <li class="flix-html-li" v-if="getSurveyType()"><router-link @click.native="closeMenu" class="flix-html-a" :to="getLink('hitklick')"><flixIcon :id="'star'" /> {{ $tc('message.feedback', 1) }}</router-link></li>
      -->
      </ul>

      <router-link class="flix-pull-right flix-navbar-brand flix-html-a" :to="'/'"><logo class="logo" /></router-link>
    </div>
  </div>
</nav>

</template>
<script>
export default {
  props: {
    data: Object
  },
  data () {
    return {
      toggleMenu: true
    }
  },
  methods: {
    getSurveyType () {
      var data = JSON.parse(this.data.atitle)
      if (typeof data.survey === 'undefined' || typeof data.survey.type === 'undefined' || data.survey.type === 'none') {
        return false
      }
      return true
    },
    closeMenu () {
      this.toggleMenu = true
    },
    getLink (link) {
      var def = { name: this.$route.name, params: Object.assign(JSON.parse(JSON.stringify(this.$route.params))) }
      def.params.page = link
      return def
    },
    checkWebinar () {
      var data = JSON.parse(this.data.atitle)
      if (typeof data.webinar === 'undefined' || !data.webinar) {
        return false
      }
      return true
    }
  }
}
</script>
<style scoped lang="sass">
  @import '@/assets/style/sass/stylesheet/flix_color_style.scss'
  .flix-navbar-brand
    display: inline
    height: inherit
    margin-right: 0
    width: 100px
    .logo
      height: 40px
      margin-bottom: 20px
      display: block
  @media(max-width: 500px)
    .flix-navbar-brand
      display: block
      height: inherit
      width: 100%
      .logo
        display: block
        width: 100%
        clear: both
  .router-link-exact-active
    border-bottom: 2px solid $brand-primary
    &.flix-navbar-brand
      border-bottom: 0
</style>
